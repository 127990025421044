import React, { useState } from 'react';
import './App.css';
import Login from './Components/Login.js';
import Recherche from './Components/Recherche.js';
import Commandes from './Components/Commandes.js';
import Journal from './Components/Journal.js';
import Infos from './Components/Infos.js';
import Demo from './Components/Demo.js';

import MenuColonne from './MenuColonne'; // Assurez-vous que le chemin d'importation est correct
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenApi, setTokenApi] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [page, setPage] = useState('Recherche');
  const [user, setUser] = useState({user: '', psw: ''});

  // const handleLogin = () => {
  //   // Ici, vous mettriez à jour l'état basé sur une logique de connexion réelle

  //   console.log('Connexion en cours ...');


  //   setIsLoggedIn(true);
  // };
  const handleLogin = async (user, psw) => {
    // Préparation du corps de la requête

//console.log('handleLogin user', user, 'password', psw)

const loginDetails = {
  user: user,
  psw: psw,
};

console.log('loginDetails', JSON.stringify(loginDetails))
  
    try {
      // Envoi de la requête POST à l'API
      let url = process.env.REACT_APP_API_URL + "/api/login";
      const response = await fetch(url, { 
        mode: 'cors',
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(loginDetails),
      });

  
      // Vérification de la réponse
      if (response.ok) {
        const data = await response.json();
        console.log('Connexion réussie', data);
        // Store the token in local storage
        localStorage.setItem('token', data.token);
        setTokenApi(data.token);
        setUser({user: user, psw: psw});
        // Mettre à jour l'état pour indiquer que l'utilisateur est connecté
        setIsLoggedIn(true);
      } else {
        // Réponses d'erreur de l'API
        console.error('Échec de la connexion');
      }
    } catch (error) {
      // Gérer les erreurs de réseau ou de parsing JSON
      console.error('Erreur lors de la connexion:', error);
    }
  };

  return (
    <div className="App">



      {isLoggedIn ? 
      // <p>Utilisateur connecté</p> 
      <>
        {/* <Button onClick={() => setIsMenuOpen(!isMenuOpen)}>Toggle Menu</Button> */}
        <Button 
        className="boutonMenuGauche" 
        onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <MenuIcon />
      </Button>
        <MenuColonne isOpen={isMenuOpen} setPage={setPage} setIsMenuOpen={setIsMenuOpen}/>
          <div className="container">
        {page=='Recherche' && <Recherche tokenApi={tokenApi}/>}
        {page=='Journal' && <Journal loginDetails={user}/>}
        {page=='Infos' && <Infos loginDetails={user}/>}
        {page=='Commandes' && <Commandes loginDetails={user}/>}
        {page=='Demo' && <Demo loginDetails={user}/>}
        {/* <Recherche tokenApi={tokenApi}/> */}
         </div>
      </>
      : (
          // Utiliser le composant Login ici et passer handleLogin comme prop
          <Login onLogin={handleLogin} />
        )}

      
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p> */}
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </div>
  );
}

export default App;
