import { type } from '@testing-library/user-event/dist/type';
import React, { useState, useEffect } from 'react';
import Fiche from './Fiche';
import PersonSearch from '@mui/icons-material/PersonSearch';

import Button from '@mui/material/Button';
import Input from "@mui/material/Input";

function Recherche ({tokenApi}) {
    const [messages, setMessages] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [socket, setSocket] = useState(null);
    const [candidats, setCandidats] = useState([]);
    const [fiches, setFiches] = useState([]);
    const [triggerEffect, setTriggerEffect] = useState(true); // Variable d'état pour contrôler l'exécution du useEffect
    const [matricule, setMatricule] = useState(""); // Variable d'état pour contrôler l'exécution du useEffect


const [state, setState] = useState({
     candidats: [],
    tokenApi: tokenApi});

    useEffect(() => {
        setFiches([])
       // let wsUrl = process.env.REACT_APP_API_WS //+ '/ws';
        // const ws = new WebSocket((window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host.replace("3000","8080") + '/ws');
        //const ws = new WebSocket((window.location.protocol === 'https:' ? 'wss://' : 'ws://') +wsUrl);
        const ws = new WebSocket(process.env.REACT_APP_API_WS);
       // const ws = new WebSocket(wsUrl);
      // console.log('ws', ws)

            ws.onopen = () => {
                console.log('ws opened');
              //  useEffect(() => {
                    // Écouter les messages entrants du socket
                    const handleMessage = (event) => {
                        const data = JSON.parse(event.data);
                        if (data.type === 'candidats') {
                            setCandidats(data.candidats); // Mettre à jour l'état avec les nouveaux candidats
                        }
                    };
            
                    ws.addEventListener('message', handleMessage);
            
                    // // Nettoyage en retirant le gestionnaire d'événements
                    // return () => {
                    //     socket.removeEventListener('message', handleMessage);
                    // };
            //    }, [socket]); // S'assurer que l'effet s'exécute à nouveau si `socket` change
            }
            ws.onclose = () => console.log('ws closed');
        
            ws.onmessage = e => {
              const message = JSON.parse(e.data);
              console.log('e', message);
            };
            // ws.onmessage = (event) => {
            //     const message = event.data;
            //     setMessages(prevMessages => [...prevMessages, message]);
            //   };
          
              ws.onerror = (error) => {
                console.error('WebSocket error:', error);
              };
          
            //   ws.onclose = () => {
            //     console.log('WebSocket disconnected');
            //   };
          
              // Enregistrer la WebSocket dans l'état
              setSocket(ws);


       // return () => socket.close();
    }, []);

    const handleSearchInputChanges = (e) => {
        setSearchValue(e.target.value.toUpperCase());
        setTriggerEffect(true); // Activer l'effet pour envoyer la requête
    }

    useEffect(() => {

        // Envoyer searchValue si sa longueur est >= 3
        if (searchValue.length >= 2 && socket && triggerEffect) {
            console.log('socket=', socket)
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`
            };

            const question = {
                type: 'cand',
                value: searchValue
            }
            console.log('question 1 ', question)

            // socket.send(question, headers);
            socket.send(JSON.stringify(question));
        }
    }, [searchValue, socket, triggerEffect]);

    useEffect(() => {
        const checkAndReconnectSocket = () => {
            if (!socket || socket.readyState === WebSocket.CLOSED) {
                // Logique pour réinitialiser et reconnecter le socket
                // Exemple : socket = new WebSocket('URL_DU_SOCKET');
                console.log('Tentative de reconnexion du socket...');
            }
        };
    
        // Vérifier la connexion socket toutes les 5 secondes
        const intervalId = setInterval(checkAndReconnectSocket, 5000);
    
        return () => {
            clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage
            if (socket) {
                socket.close(); // Assurez-vous de fermer le socket lors du démontage
            }
        };
    }, [socket]); // Dépendances de useEffect

    const handleLiClick = (value) => {
        setSearchValue(value); // Mettre à jour l'input de recherche avec la valeur de l'élément <li> cliqué
        setCandidats([]); // Vider la liste des candidats
        setTriggerEffect(false) // Désactiver l'effet pour empêcher l'envoi de la requête

        const pseudoEvent = {
            preventDefault: () => {} // Fonction vide pour simuler preventDefault
        };
        
        callSearchFunction(pseudoEvent, value); // Déclencher la recherche
    };

    const resetInputField = () => {
        setSearchValue('')
    }

    // const callSearchFunction = (e) => {
    //     e.preventDefault();
    //     sendSearch(searchValue);
    //     resetInputField();
    // }

    const callSearchFunction = async (e, v) => {
        e.preventDefault();
        if(searchValue.length > 0) {
            let name = searchValue// searchValue; // Utiliser la valeur de l'input de recherche comme 'name'
            if(v) {name = (v);}

        const currentPage = 1; // Définir la page actuelle, ajustez selon votre logique
        const pageSize = 10; // Définir la taille de page, ajustez selon votre logique
        let url = process.env.REACT_APP_API_URL + `/api/candid?name=${name}&page=${currentPage}&size=${pageSize}`;
        const token = localStorage.getItem('token'); // Récupérer le token si nécessaire pour l'authentification
        console.log('++ token=', token)
    // console.log(url);
        try {
           
            const response = await fetch(url, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${token}`, // Inclure le token dans les headers si nécessaire
                    'Authorization': `${token}`, // Inclure le token dans les headers si nécessaire
                },
            });
    
            if (!response.ok) {
                throw new Error(`Erreur: ${response.status}`);
            }
            
            const data = await response.json();
           
            console.log("Données de la recherche = ", data); // Traiter la réponse
            if(data){
                setFiches(data); // Mettre à jour l'état avec les candidats reçus
            }else{
                alert('Aucun candidat en base ANAEL pour ' + name + ' !');
                setFiches([]); // Mettre à jour l'état avec un tableau vide
            }


        } catch (error) {
            console.error("Erreur lors de l'envoi de la requête:", error, error.message);
        }
        }
        resetInputField(); // Réinitialiser le champ de saisie
    };


function supprimeCandidate(mat) {
    console.log('supprimeCandidate', mat)
    candidats.splice(candidats.findIndex(el => el === mat), 1);
    setCandidats([...candidats]);
    fiches.splice(fiches.findIndex(el => el.PERMAT === mat), 1);
    setFiches([...fiches]);
}



    function sendSearch(search) {
        const question = {
            type: 'cand',
            value: search
        };
        console.log('question -- ', question);
        socket.send(JSON.stringify(question));
    }

    const handleItemClick = (value) => {
        // setInputValue(value); // Met à jour l'input avec la valeur de l'item cliqué
        // triggerSearch(value); // Déclenche la recherche avec cette valeur
        handleLiClick(); // Réinitialise la liste des items
        //setSearchValue(value)
         //callSearchFunction(); // Déclenche la recherche
    };


const testOptionsc =  async () => {
    const token = localStorage.getItem('token');
    console.log('++ token=', token)
    console.log('token=', token);

    const name = 'DURAND'; // Utiliser la valeur de l'input de recherche comme 'name'
    const currentPage = 1; // Définir la page actuelle, ajustez selon votre logique
    const pageSize = 10; // Définir la taille de page, ajustez selon votre logique
   let url = process.env.REACT_APP_API_URL + "/api/optionsc";
console.log(url);
    try {
         
        let response = await fetch(url, {
            mode: 'cors',
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${token}`, // Inclure le token dans les headers si nécessaire
                'Authorization': `${token}`, // Inclure le token dans les headers si nécessaire
            },
        });

        if (!response.ok) {
            throw new Error(`Erreur: ${response.status}`);
        }
        
        const data = await response.json();
       
        console.log('data=',data); // Traiter la réponse
       // setFiches(data); // Mettre à jour l'état avec les candidats reçus

    } catch (error) {
        console.error("Erreur lors de l'envoi de la requête:", error, error.message);
    }
    }


    return (
        <>
       
        <h1>Recherche de candidat</h1>
        <div className="search div-conteneur">
            <div className='ensemble_recherche'>

           
            <div className='recherche'>
                {/* <h1>Recherche de candidat</h1> */}
                <Input
                    value={searchValue}
                    placeholder='Nom du candidat'
                    onChange={handleSearchInputChanges}
                    type="text"
                    style={{width: '100%'}}
                    />
                <Button 
                onClick={callSearchFunction} 
                style={{ 
                    padding: '0', 
                    minWidth: 'auto', 
                    width: 'auto', 
                    height: 'auto',
                    border: '2px solid #007bff' // Exemple de cadre bleu
                }}
                type="submit"><PersonSearch/></Button>
                {/* <Button onClick={testOptionsc}  variant="contained">Test</Button> */}
            </div>


            <ul className="ul-liste">
                {candidats.map((candidat, index) => (
                      <li key={index} onClick={() => handleLiClick(candidat)}>  {/* handleLiClick */}
                      {candidat}
                  </li>
                ))}
            </ul>

            </div>


        </div>
         <div>
            {fiches.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th className='identite'>Identté</th>
                            <th className='identite col-12'>Identté</th>
                            <th className='coordonnees'>Coordonnées</th>
                            <th className='info col-12'>Matricule</th>
                            <th className='info col-12'>Dispojob</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fiches.map((fiche, index) => (
                            <tr key={fiche.PERMAT} onClick={() => setMatricule(fiche.PERMAT)}>
                                <td className='identite col-12'>{fiche.PERNOM} &nbsp; {fiche.PERPRE}</td>
                                <td>{fiche.PERNOM}<br />{fiche.PERPRE}</td>
                                <td>{fiche.PERRU1}<br/>{fiche.PERRU2 ? <>{fiche.PERRU2}<br/></> : ""}{fiche.PERCPO} &nbsp; {fiche.PERVIL}</td>
                                <td className='col-12'>{fiche.PERMAT}</td>
                                <td className='col-12'>{fiche.PERMAN}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div></div> // Ou simplement null pour ne rien afficher
            )}
     </div>
     {matricule !== '' && (
    fiches.find(el => el.PERMAT === matricule)
    ? <Fiche key={matricule} cand={fiches.find(el => el.PERMAT === matricule)} suppc={supprimeCandidate} />
    : <div></div> // Ou simplement null pour ne rien afficher
)}
     {/* {matricule !== '' && <Fiche key={matricule} cand={fiches.find(el=>el.PERMAT==matricule)} />} */}
     </>
    );

    
    function setSearch(search) {
        const question = {
            type: 'cand',
            value: search
        }
        console.log('question -- ', question)

        socket.send(JSON.stringify(question)  )
    }

    


}


export default Recherche;