import React, { useState, useEffect } from "react";

export default function Commandes() {
    const Month = new Date().getMonth() + 1;
    const daysInMonth = new Date(new Date().getFullYear(), Month, 0).getDate();

    const clients = [
        { lib: 'ANGE', code: '001001' },
        { lib: 'IFEA', code: '001002' },
        { lib: 'DECAUX', code: '001003' },
        { lib: 'AIRBUS', code: '001004' },
        { lib: 'PLD', code: '001005' },
        { lib: 'CARREFOUR', code: '001006' },
        { lib: 'AUCHAN', code: '001007' },
        { lib: 'ELECTRODEPOT', code: '001008' },
        { lib: 'ARVATO', code: '001009' },
        { lib: 'IKEA', code: '001010' },
        { lib: 'LECLERC', code: '001011' },
        { lib: 'LEROY MERLIN', code: '001012' },
        { lib: 'LIDL', code: '001013' },
        { lib: 'MCDONALD', code: '001014' },
        { lib: 'MCDONALD', code: '001015' },
        { lib: 'MCDONALD', code: '001016' },
        { lib: 'ORANGE', code: '001017' },
        { lib: 'SFR', code: '001018' },
        { lib: 'TOTAL', code: '001019' },
        { lib: 'VEOLIA', code: '001020' },
        { lib: 'VOLKSWAGEN', code: '001021' },
        { lib: 'ZARA', code: '001022' },
    ];
    const missions = [
        { code: '001001', date: '2021-07-04', nbj:4, qte: 5, state:'OK' },
    ]

    const [ state, setState ] = useState({clients: [], missions:[], agence:'655', year:'2021', month:'01'});
    const [ ages, setAges ] = useState({agences:[]});

    useEffect(() => {
        // Logique à exécuter lorsque [`cand`](command:_github.copilot.openSymbolFromReferences?%5B%7B%22%24mid%22%3A1%2C%22fsPath%22%3A%22c%3A%5C%5Cdev%5C%5Creact%5C%5Canael%5C%5Csrc%5C%5CComponents%5C%5CRecherche.js%22%2C%22_sep%22%3A1%2C%22external%22%3A%22file%3A%2F%2F%2Fc%253A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22path%22%3A%22%2Fc%3A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22scheme%22%3A%22file%22%7D%2C%7B%22line%22%3A245%2C%22character%22%3A33%7D%5D "src/Components/Recherche.js") change
        console.log("Recherche la liste des clients");
        getAgences();
        getClients();
      }, []); 

      useEffect(() => {
        // Logique à exécuter lorsque [`cand`](command:_github.copilot.openSymbolFromReferences?%5B%7B%22%24mid%22%3A1%2C%22fsPath%22%3A%22c%3A%5C%5Cdev%5C%5Creact%5C%5Canael%5C%5Csrc%5C%5CComponents%5C%5CRecherche.js%22%2C%22_sep%22%3A1%2C%22external%22%3A%22file%3A%2F%2F%2Fc%253A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22path%22%3A%22%2Fc%3A%2Fdev%2Freact%2Fanael%2Fsrc%2FComponents%2FRecherche.js%22%2C%22scheme%22%3A%22file%22%7D%2C%7B%22line%22%3A245%2C%22character%22%3A33%7D%5D "src/Components/Recherche.js") change
        console.log("Recherche du nouveau planning");
        getClients();
      }, [state.agence, state.year, state.month]); 


const getAgences = async () => {
    try {
        const url  = process.env.REACT_APP_API_URL + '/api/agences';
        const token = localStorage.getItem("token");
        console.log('url, token', url , token);
        const response = await fetch(url, {
          method: "GET",
        //  mode: "cors",
        //   headers: {Authorization: `${token}`, Accept: "*/*", "Content-Type": "application/json", "Access-Control-Allow-Origin": "*"   }
        headers: {
            'Content-Type': 'application/json',
            'Authorization': '`${token}`',
        }
        //   headers: new Headers({
        //     'Authorization': `${token}`,
        //     'Content-Type': 'application/x-www-form-urlencoded'})
            }
        );
        const agences = await response.json();
        console.log('agences = ', agences);    
        setAges({agences:agences });
    } catch (error) {
        console.error("Error fetching agences:", error);
    }
}


const getClients = async () => {
    try {
       // event.preventDefault();
        console.log("process.env.REACT_APP_WINWS", process.env.REACT_APP_WINWS)
        const wws = new WebSocket(process.env.REACT_APP_WINWS);
        wws.onopen = () => {
            console.log('Winws opened');
            let d1 = state.year + '-' + state.month + '-01';
            let d2 = state.year + '-' + state.month + '-' + daysInMonth;
            console.log('agence=', state.agence, 'month=', state.month, 'year=', state.year, 'd1', d1, 'd2', d2);
            if(d1.length === 10 && d2.length === 10){
            wws.send(JSON.stringify({ action: "cde", age:state.agence, d1:d1, d2:d2 }));
            }
            // wws.send(JSON.stringify({action:"cde",age:"655",d1:"2021-01-01",d2:"2021-01-31"}));
            const handleMessage = (event) => {
              //  console.log('event--------------------------', event);
              //  const data = JSON.parse(event.data);
                // if (data.type === 'candidats') {
                   // setCandidats(data.candidats); // Mettre à jour l'état avec les nouveaux candidats
                    // }
            };
            wws.addEventListener('message', handleMessage);
        }
        wws.onclose = () => console.log('ws closed');
    
        wws.onmessage = e => {
          const message = JSON.parse(e.data);
         // console.log('e', message);
          if (message.clients) {
            setState({ ...state, clients: message.clients, missions: message.lines });
        }
        };
      
        wws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
       // const data = await response.json();
 
    } catch (error) {
        console.error("Error fetching clients:", error);
    }
}



const thStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 4,
    fontSize: '12px',
    backgroundColor: 'white',
    width: '35px', padding: '0px', textAlign: 'center',
    borderRight: '1px solid #CCC',
};


// Le tableau des dates affichées dans en front
    const columnsDate = [];
    for (let i = 1; i <= daysInMonth; i++) {
        const isWeekend = new Date(new Date().getFullYear(), Month - 1, i).getDay() === 0 || new Date(new Date().getFullYear(), Month - 1, i).getDay() === 6;
        const dayStyle = isWeekend ? { ...thStyle, color: 'red' } : thStyle;

        columnsDate.push(<th key={i} style={dayStyle}>{new Date(new Date().getFullYear(), Month - 1, i).toLocaleString('default', { weekday: 'short' }).charAt(0)}{i}</th>);
    }

    const tableHeadStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 4,
        fontSize: '12px',
        backgroundColor: 'white',
        borderRight: '1px solid #CCC',
    };

    const tdStyle = {
        borderRight: '1px solid #CCC',
        width: '35px',
        maxWidth: '35px',
        padding: '0px',
        margin: '0px',
        textAlign: 'center',
    };

    const containerStyle = {
        overflow: 'scroll',
        height: '42em',
        border: '1px solid black',
    };

    const tableStyle = {
        width: 'max-content',
        borderCollapse: 'collapse',
        marginTOP: '0px',
    };

    // Style de la première colonne première ligne du tableau
const a1 = {
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 5,
    fontSize: '12px',
    backgroundColor: 'white',
    width: '100px',
    borderRight: '1px solid #CCC',
};

 const missionStyle = {
        fontSize: '12px',
        position: 'relative',
        top: 0,
        left: '0px',
        zIndex: 1,
        backgroundColor: 'red',
        width: '140px',
        borderRight: '1px solid #CCC',
        textAlign: 'center',
        overflow: 'hidden',
    };

    /*
La div contenant le tableau a une hauteur fixe de 400px et une largeur de 100%.
    */

    return (
        <div style={containerStyle} >


            {/* <select value={ages.agences.ENTAGE} onChange={(e) => setState({ ...state, agences: { ...state.agences, ENTAGE: e.target.value } })}> */}
            <select value={state.agence} onChange={(e) => setState({...state, agence:e.target.value })}>
                {ages.agences.map((agence) => (
                    <option key={agence.Entage} value={agence.Entage}>
                        {agence.Entrso}
                        {/* {console.log('agence', agence.Entage, agence.Entrso)} */}
                    </option>
                ))}
            </select>
            <select value={state.month} onChange={(e) => setState({...state, month:e.target.value })}>
                <option value="01">Janvier</option>
                <option value="02">Février</option>
                <option value="03">Mars</option>
                <option value="04">Avril</option>
                <option value="05">Mai</option>
                <option value="06">Juin</option>
                <option value="07">Juillet</option>
                <option value="08">Août</option>
                <option value="09">Septembre</option>
                <option value="10">Octobre</option>
                <option value="11">Novembre</option>
                <option value="12">Décembre</option>
            </select>

            <select value={state.year} onChange={(e) => {
                console.log('year', e.target.value);
                setState({...state, year:e.target.value })}}>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
            </select>

            {/* {state.clients.length === 0 && <Spinner />} */}

            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={a1}>Client<div></div></th>
                        {columnsDate}
                    </tr>
                </thead>
                <tbody>
                    {state.clients.map((client) => (
                        <tr key={client.TIACLI}>
                            <td style={{ position: 'sticky', left: 0, zIndex: 2, backgroundColor: 'white', width: '100px',  borderRight: '1px solid #CCC',}}>{client.TIARSO}</td>
                            {Array.from({ length: daysInMonth }, (_, index) => (
                               
                                <td
                                    key={client.TIAAGE + client.TIACLI + (index + 1) }
                                    id={client.TIAAGE + client.TIACLI + (index + 1)}
                                    style={tdStyle}
                                >
                                </td>
                            ))
                            
                            
                        }
                        {/* {console.log('client', client.TIACLI, client.TIAAGE, 2 + 1)} */}
                        </tr>
                    ))}
                    
                    {state.missions.map((mission) => {
                        let deb  = new Date(mission.CTCDEB).getDate();
                        let fin  = new Date(mission.CTCFIN).getDate();
                        let nbj = 0
                        if(fin){
                            nbj = fin - deb + 1;
                        }

                        const cible = document.getElementById(mission.CTCAGE + mission.CTCCLI + deb); 
                        if (cible){
                            let color = 'red';
                          //  if(mission.CTCSTA === 'HON'){cible.style.backgroundColor = 'green';}else{cible.style.backgroundColor = 'red';}
                            if(mission.CTCSTA === 'HON'){color = '#CCFF99';}else{color = ' #FF5733 ';}
                            //cible.style.backgroundColor = 'blue';
                            cible.innerHTML = "<p style='border-radius: 25%; background-color:"+color+"; font-size: xs'>"+ nbj + "</p>";
                           // cible.innerHTML = "<p style='border-radius: 25%; background-color:"+color+"'>"+(fin - deb + 1) + "</p>"
                           // cible.innerHTML = "<p>"+(fin - deb + 1) + " " +  mission.CTCSTA+ "</p>"
                        }  

                        // {console.log('mission', mission.CTCCLI ,  client.TIACLI, mission.CTCAGE,  client.TIAAGE); }    
                        // if (mission.CTCCLI === client.TIACLI && mission.CTCAGE === client.TIAAGE && new Date(mission.CTCDEB).getDate() === index + 1) {
                        //     return (
                        //         <>
                        //         <div
                        //             key={mission.CTCCTR + mission.CTCAVE  + mission.CTCDEB + index}
                        //             style={{
                        //                 backgroundColor: mission.state === 'OK' ? '#90ee90' : '#ffd580',
                        //                 height: '20px',
                        //                 width: '20px',
                        //                 borderRadius: '50%',
                        //                 margin: 'auto',
                        //             }}
                        //             >
                        //             {mission.CTCLIB}
                        //         </div>
                        //         <p 
                        //              key={mission.CTCCTR + + mission.CTCAVE + mission.CTCDEB + index + 'p'}
                        //             style={missionStyle}>{mission.nbj}</p>
                        //         </>
                        //     );
                        // }
                    })
                    
                    }
                </tbody>
            </table>
        </div>
    );
}
