import React, { useState, useEffect } from "react";
// import axios 


function Journal({ loginDetails}) {    

useEffect(() => {
    console.log("Login details", loginDetails);
    getPasserelleToken();
    getJournal();
}, []);

// useEffect(() => {
//     console.log("Login details", loginDetails);
//     getJournal();
// }, [tokenPasserelles]);


const [tokenPasserelles, setTokenPasserelles] = useState("");
const [actions, setActions] = useState([]);

async function getPasserelleToken() {
    try {
        const urlp = process.env.REACT_APP_PASSERELLE + "/api/connexion";
        const response = await fetch(urlp);
        if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
        }
        const data = await response.json(); // Assurez-vous que la réponse est bien du JSON
        console.log(data); // Affichez les données pour vérification
        setTokenPasserelles(data.token);
        // Traitement de vos données ici
    } catch (error) {
        console.error("Erreur lors de l'obtention du token:", error);
    }
}



const getJournal = async () =>{
    try{
        const urlp2 = process.env.REACT_APP_PASSERELLE + "/api/actions?id=Wz6lGwJiT22NuGKtzyhD";
        console.log('urlp', urlp2)
        const response = await fetch(urlp2, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenPasserelles
              }
              })
           
              
        // Vérification de la réponse
        // if (response.status === 200) {
            const data = await response.json();
            console.log('Journal', data);
            if(data){
            setActions(data);}
            else{
                setActions([]); 
            }
        // } else {
        //     alert(response.status)
        //     // Réponses d'erreur de l'API
        //     console.error('Échec de la connexion', response);
        //     alert('1-Erreur de connexion à Passerelles : ' + JSON.stringify(response))
        // }
    } catch (error) {
        // Gérer les erreurs de réseau ou de parsing JSON
        console.error('Erreur lors de la connexion:', error);
        alert('1-KO connexion à Passerelles : ' + error)
        }
}

const [visibleMessages, setVisibleMessages] = useState({});

const toggleMessageVisibility = (id) => {
  setVisibleMessages(prevState => ({
    ...prevState,
    [id]: !prevState[id]
  }));
};


return (
    <div>
        <h1>Synchronisation ANAEL TT</h1>
        <p>Synchronisations Dispojob - ANAEL TT</p>
        {actions.length > 0 && (
            <div className="table-responsive">
  <table>
  <thead>
      <tr>
          <th className='worker'>Worker</th>
          <th className='date'>Date</th>
          <th className='statut'>Statut</th>
          <th className='Message'>Message webservice Infor</th>
      </tr>
  </thead>
  <tbody>
      {actions.sort((a, b) => new Date(b.date) - new Date(a.date)).map((a, index) => (
          <tr key={a.id + "a"}>
              <td>{a.id} &nbsp;{a.first_name} &nbsp; {a.last_name}</td>
            <td>{new Date(a.date).toLocaleString()}</td>
              {/* <td>{a.date}</td> */}
              <td>{a.statut}</td>
              <td>
              {a.message.split(/\r\n/g).map((line, index, array) => (
                <span key={index + "a"}>
                  {line.startsWith("PER") ? line.substring(6).trim() : line}
                {/* {index < array.length - 1 && <br />} */}
                </span>
            ))}
            </td>
          </tr>
      ))}
  </tbody>
</table>

<div className="table-mobile">
{actions.sort((a, b) => new Date(b.date) - new Date(a.date)).map((a, index) => (
        //   <tr key={a.id}>
        <>
        <div 
        id={a.id + "L"}
        key={a.id + "L"}
        style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent:'space-between',
        backgroundColor: a.statut === "OK" ? "#90ee90" : "#ffd580" }} 
        onClick={() => toggleMessageVisibility(a.id)} 
        >
            <div>{a.statut}</div>
            <div>{a.first_name} &nbsp; {a.last_name}</div>
            <div>{new Date(a.date).toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' })}</div>
        </div >
              <div id={a.id} style={{ display: visibleMessages[a.id] ? 'block' : 'none' }}>
              {a.message.split(/\r\n/g).map((line, index, array) => (
                  <span key={index + "b"}>
                 {line.startsWith("PER") ? line.substring(6).trim() : line}
                {index < array.length - 1 && <br />}
                </span>
            ))}
            </div>
      </>
      ))}
</div>


</div>
        )}

    </div>
);

}

export default Journal;


